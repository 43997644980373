<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="8" width="3" height="3" fill="currentColor" />
    <rect x="28" width="3" height="3" fill="currentColor" />
    <path
      d="M38 14V14C29.1486 26.2936 10.8514 26.2936 2 14V14"
      stroke="currentColor"
      stroke-width="3"
    />
  </svg>
</template>
